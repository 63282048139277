.validations {
  animation-name: show-validation-message-delayed;
  animation-duration: $cep-form-validation-message-delay;
  animation-timing-function: linear;

  // Used to show validation messages and all styles that belong to them with a short delay.
  // Without that it can happen that a user presses e.g. the submit button without recognizing the button click
  // because CEP first shows the error message (because of blur observer) which shifts the whole document for the size
  // of the validation message downwards. Consequently the click does not target the submit button anymore.
  @keyframes show-validation-message-delayed {
    0% {
      position: absolute;
      opacity: 0;
      left: -9999px;
    }

    99% {
      position: absolute;
      opacity: 0;
      left: -9999px;
    }

    100% {
      opacity: 1;
    }
  }

  .validation {
    padding: $input-padding-y;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  p {
    margin: 0;
  }

  & + .validations {
    margin-top: $input-padding-y;
  }
}

.help-block {
  margin: 0;
  padding: 0;
}

.question-wrapper.large {
  .validations {
    margin-top: $input-padding-y;
  }
}

@each $state, $data in $cep-form-validation-states {
  .has-#{$state} {
    @include form-control-validation(map_get($data, 'color'), map_get($data, 'bordercolor'), map_get($data, 'bgcolor'));
  }
}

.input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
