* {
  text-align: right;
}

.question-label {
  .question-index {
    margin-right: 0;
    margin-left: $input-padding-x-sm;
  }
}

.tab-content {
  margin-right: -2px;
}

.unicorn-search {
  .unicorn-search__inner {
    .unicorn-search__input {
      margin-left: 0;
      margin-right: 6px;
    }
  }

  .unicorn-chip {
    padding-left: 0;
    padding-right: map-get($spacers, 2);

    button {
      text-align: center !important;
    }
  }

  .unicorn-chip__label {
    padding-left: map-get($spacers, 1);
    padding-right: 0;
  }
}

@if ($enable-rounded) {
  .input-group .form-control:last-child {
    border-bottom-left-radius: $input-border-radius;
    border-bottom-right-radius: 0;
    border-top-left-radius: $input-border-radius;
    border-top-right-radius: 0;
  }

  .input-group .form-control:first-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: $input-border-radius;
    border-top-left-radius: 0;
    border-top-right-radius: $input-border-radius;
  }
}

.special-number-widget,
.date-time-picker-component {
  .short-input {
    margin-right: 0;
    margin-left: map_get($spacers, 2);
  }
}

.meridian {
  text-align: center !important;
}

.checkbox,
.radio {
  .label-input {
    padding-left: 0;
    padding-right: 15px;
  }

  .hint {
    padding-left: 0;
    padding-right: 27px;
  }
}

.type-assessment-factor-group .card-controls {
  text-align: left;
}

.card-header-action {
  float: left;
}

@if ($cep-enable-custom-checkbox-radio) {
  .radio,
  .checkbox {
    label {
      padding-right: add($cep-custom-checkbox-radio-button-size, $input-padding-x);
      padding-left: 0;
    }

    input[type='radio'],
    input[type='checkbox'] {
      + .key {
        right: 0;
        left: auto;
      }

      &:checked + .key {
        &:after {
          position: absolute;
          left: -1px;
        }
      }

      &:focus + .key {
        &:after {
          left: -1px;
          top: -1px;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .has-error .question-content-wrapper {
    margin-right: 0;
    margin-left: $input-padding-x-lg;
  }
}
