.question-reminder {
  position: fixed;
  margin-top: $popover-arrow-height;
  right: $input-padding-y;
  top: $input-padding-x;
  left: auto;
  display: none;

  &.in {
    display: block;
  }
  max-width: 200px;

  &.minimized {
    cursor: pointer;
    .hide-if-minimized {
      display: none;
    }
  }

  &:not(.minimized) {
    .hide-if-maximized {
      display: none;
    }
  }

  .arrow {
    left: 50%;
    transform: translateX(-50%);
    top: subtract(-$popover-arrow-height, $popover-border-width);

    &::before {
      top: 0;
      border-width: 0 calc($popover-arrow-width / 2) $popover-arrow-height calc($popover-arrow-width / 2);
      border-bottom-color: $popover-arrow-outer-color;
    }

    &::after {
      top: $popover-border-width;
      border-width: 0 calc($popover-arrow-width / 2) $popover-arrow-height calc($popover-arrow-width / 2);
      border-bottom-color: $popover-arrow-color;
    }
  }

  .with-button {
    display: flex;
    margin-bottom: 0.5rem;

    .btn {
      font-size: $font-size-sm;
      padding: 0.25rem 0.5rem;
    }
  }
}

@include media-breakpoint-down(sm) {
  .question-reminder.popover {
    .arrow {
      display: none;
    }
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    width: 100%;
    max-width: initial;
    margin-top: 0;
    left: 0;
    right: 0;
    top: 0;

    button.minimizer {
      display: none;
    }

    p {
      display: inline;
      margin: 0;

      &.with-button {
        text-align: right;
        display: inline;
      }
    }
  }
}
