.question-label {
  margin-top: $input-padding-y;
  margin-bottom: $input-padding-y;

  .question-index {
    margin-right: $input-padding-x-sm;
  }
  .question-caption {
    display: inline;
  }
  .question-subtext {
    display: block;
  }
}
