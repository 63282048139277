@import 'cep-variables';

@import 'mixins/mixins';
@import 'glyphicons';

//## Questionnaire
.question-form {
  @import 'unicorn-search';
  @import 'assessment-factor-groups';
  @import 'date-time';
  @import 'form';
  @import 'labels';
  @import 'lists';
  @import 'loading-spinner';
  @import 'number-with-units';
  @import 'plain-text';
  @import 'headlines';
  @import 'question';
  @import 'question-annotation';
  @import 'question-reminder';
  @import 'question-validations';
  @import 'selection';
  @import 'structs';
  @import 'tables';
  @import 'pager';
  @import 'questionnaire-animation';
  @import 'radios-checkboxes';
  @import 'file-upload';
  @import 'text';
  @import 'summary';
}

@import 'bs-datepicker';
@import 'from-kb-newlines';
@import 'pagination';

[dir='rtl'].question-form {
  @import 'rtl';
}

html[data-environment='coded-ui-test'] {
  scroll-behavior: auto !important;
}
