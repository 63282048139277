.unicorn-search {
  min-width: $cep-short-input-width;
  position: relative;

  .unicorn-search__inner--focused {
    border-color: $input-focus-border-color;
    outline: none;

    @if $enable-shadows {
      @include box-shadow($input-box-shadow, $input-focus-box-shadow);
    } @else {
      box-shadow: $cep-box-shadow;
    }
  }

  &.unicorn-search--max-reached {
    .unicorn-search__inner {
      .unicorn-search__input input {
        min-width: 10px;
        width: 10px;
      }
      .unicorn-search__icon {
        display: none;
      }
    }
  }

  &.unicorn-search--active {
    z-index: 4;
  }

  .unicorn-search__inner {
    cursor: text;
    padding: 2px;
    z-index: 3;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    height: auto;

    &.unicorn-search__inner--open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .unicorn-search__input {
      flex-grow: 1;
      display: flex;
      align-items: center;
      margin-left: 6px;
      line-height: subtract($input-height-inner, (2 * 3.75px));

      i.unicorn-search__icon {
        margin-right: 3px;
        top: 0;
      }

      input {
        &::-ms-clear {
          display: none;
        }
        width: 80px;
        flex-grow: 1;
        height: subtract($input-height-inner, 5.5px);
        line-height: subtract($input-height-inner, 5.5px);
        border: none;
        &:focus {
          outline: none;
        }
      }
    }

    .unicorn-chip {
      padding-left: map_get($spacers, 2);
      margin: 2px;
      border: $btn-border-width solid $gray-400;
      border-radius: $btn-border-radius;

      position: relative;
      background: $gray-400;
      color: $gray-700;

      button {
        border: none;
        display: inline;
        background: none;
        width: $cep-unicorn-search-remove-btn-size;
        line-height: $cep-unicorn-search-remove-btn-size;
        height: 100%;
        padding: 0;
        margin: 0;
        &:focus,
        &:hover {
          color: $danger;
        }

        i {
          -webkit-text-stroke: 2px $gray-400;
        }
      }
    }

    .unicorn-chip__label {
      padding-right: map-get($spacers, 1);
    }
  }

  .unicorn-suggestions {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    top: calc(100% - 1px);

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      max-height: 300px;
      width: 100%;
      overflow: auto;
      border: $input-border-width solid $input-border-color;
      border-top: none;
      border-bottom-left-radius: $input-border-radius;
      border-bottom-right-radius: $input-border-radius;
      background: $input-bg;

      li.unicorn-suggestions__headline {
        padding: 2px 5px;
        border-bottom: $input-border-width solid $input-border-color;
      }

      li.unicorn-suggestions__result {
        button,
        span {
          width: 100%;
          background: none;
          border: none;
          text-align: left;
          display: block;
          padding: 5px 6px;
        }

        button.active {
          background: #ccc;
        }

        .unicorn-suggestions__result--already-selected {
          color: #ccc;
        }
      }
    }
  }
}

.unicorn-search--disabled {
  cursor: not-allowed;
  .unicorn-search__inner {
    min-height: $input-height-inner;
    background: $input-disabled-bg;
  }
}
