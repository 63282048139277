//all devices
.struct-item-container {
  border: 1px solid $gray-200;
  padding: $input-padding-y $input-padding-x;
  border-radius: $border-radius;

  .question-content-wrapper .short-input {
    max-width: 100%;
  }

  .question-template {
    margin-bottom: $input-padding-y;
  }

  .struct-item {
    .short-answer,
    .unicorn-search {
      min-width: auto;
    }
  }
}

//2 columns on devices > screen-sm (by default > 768px width)
@include media-breakpoint-up(md) {
  .struct-item {
    width: 50%;
    display: inline-block;
    padding: $input-padding-x;
    position: relative;
    vertical-align: top;

    .short-answer,
    .unicorn-search {
      width: 100%;
      min-width: auto;
    }
    .question-wrapper {
      .question-content-wrapper {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

//validation padding
.validations .validation.parent-validation,
.validations .validation.parent-validation:last-of-type {
  //.struct-item-container .has-error .question-content-wrapper {
  margin-bottom: $input-padding-y;
}
