$assessment-factor-group-state: (('danger', $danger), ('warning', $warning));

.type-assessment-factor-group {
  .collapsible-assessment-factor-group-heading {
    cursor: pointer;

    a,
    a label {
      text-decoration: none;
      cursor: pointer !important;
    }

    .question-label {
      display: inline;
    }
  }

  .card-controls {
    text-align: right;

    .btn {
      margin-left: map_get($spacers, 2);
    }
  }

  @if not($cep-show-assessment-factor-group-question-index) {
    .question-index {
      display: none;
    }
  }
}

.card-header-closeable button.card-header-action {
  float: right;
}

.card-header-collapsible button.card-header-action {
  width: 100%;
  .glyphicon {
    float: right;
  }
  cep2-input-label {
    float: left;
  }
}

button.card-header-action {
  color: inherit;
  background-color: transparent;
  border: none;
  padding-top: 3px;
  padding-bottom: 2px;

  &:focus {
    border-color: $input-focus-border-color;
    outline: none;

    @if $enable-shadows {
      @include box-shadow($input-box-shadow, $input-focus-box-shadow);
    } @else {
      box-shadow: $input-focus-box-shadow;
    }
  }
}

@each $state in $assessment-factor-group-state {
  .card-#{nth($state, 1)} {
    border-color: nth($state, 2);

    .card-header {
      color: $white;
      background-color: nth($state, 2);
      border-bottom-color: nth($state, 2);
    }

    .card-controls .btn {
      color: nth($state, 2);
    }
  }
}

.unicorn-chip button {
  &:focus {
    border-color: darken($border-color, 10%);
    outline: none;
    @if $enable-shadows {
      @include box-shadow($input-box-shadow, $input-focus-box-shadow);
    } @else {
      box-shadow: $input-focus-box-shadow;
    }
  }
}

.afg-overlay-container {
  position: relative;
}

.afg-overlay {
  position: absolute;
  background-color: $cep-delete-confirm-overlay-warning-color;
  z-index: 100;
  cursor: pointer;
  @include transition($modal-transition);
}

.afg-overlay-body {
  position: absolute;
  width: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.afg-overlay-body-text {
  font-size: $font-size-base * 1.5;
  color: $cep-delete-confirm-overlay-text-color;
}
