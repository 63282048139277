// new question fade-in
@if ($cep-enable-new-question-fade-in) {
  .question-container.new {
    animation-name: newQuestions;
    animation-duration: $cep-new-question-fade-in-duration;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;

    @keyframes newQuestions {
      from {
        opacity: 0;
        transform: $cep-new-question-fade-in-transform;
      }

      to {
        opacity: 1;
        transform: none;
      }
    }
  }
}
