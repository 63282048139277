.type-date {
  .date-time-picker-component {
    .short-input {
      margin-right: map_get($spacers, 2);
    }

    .timepicker {
      padding: 0;

      input,
      .btn.meridian {
        width: 2em;
        height: add($input-height-inner, -2px);
        border: 0;
        padding: 0;
      }

      .btn.meridian {
        min-width: 4em;
        width: auto;
      }
    }
  }
}
