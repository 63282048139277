$floating-alert-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.26) !default;

.alert-sticky {
  position: fixed;
  z-index: 100;
  @include box-shadow($floating-alert-shadow);

  .pagination-container-top & {
    top: 0;
  }

  .pagination-container-bottom & {
    bottom: 0;
  }
}

.alert button.close {
  margin-left: $input-padding-x;
  position: relative;
  bottom: 2px;
}
