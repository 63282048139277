@import 'cep-variables';
@import 'mixins/mixins';

/* .bs-datepicker */
.bs-datepicker {
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
  background: $cep-datepicker-bg;
  position: relative;
  z-index: 1;
  @include box-shadow($cep-datepicker-box-shadow);

  &:after {
    clear: both;
    content: '';
    display: block;
  }

  button:focus,
  td:focus {
    background-color: $cep-datepicker-body-btn-highlighted-color;
    outline: none;
  }

  /* button */
  button:hover,
  button:focus,
  button:active,
  input:hover,
  input:focus,
  input:active,
  &-btns button:hover,
  &-btns button:focus,
  &-btns button:active,
  &-predefined-btns button:active,
  &-predefined-btns button:focus {
    outline: none;
  }

  /* .bs-datepicker-head */
  &-head {
    min-width: 270px;
    height: 50px;
    padding: 10px;
    text-align: justify;
    @include border-radius(3px 3px 0 0);

    &:after {
      content: '';
      display: inline-block;
      vertical-align: top;
      width: 100%;
    }

    /* .bs-datepicker-head button */
    button {
      display: inline-block;
      vertical-align: top;
      padding: 0;
      height: 30px;
      line-height: 30px;
      border: 0;
      background: transparent;
      text-align: center;
      cursor: pointer;
      color: $cep-datepicker-header-btn-color;
      transition: 0.3s;

      &[disabled],
      &[disabled]:hover,
      &[disabled]:active {
        background: $cep-datepicker-header-btn-disabled-background;
        color: $cep-datepicker-header-btn-disabled-color;
        cursor: not-allowed;
      }

      &.next,
      &.previous {
        width: 30px;
        height: 30px;
        @include border-radius(50%);

        span {
          font-size: 28px;
          line-height: 1;
          display: inline-block;
          position: relative;
          height: 100%;
          width: 100%;
          @include border-radius(50%);
        }
      }

      &.current {
        max-width: 155px;
        padding: 0 13px;
        @include border-radius(15px);
      }
    }
  }

  &-head {
    button {
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
      &:active {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  /* .bs-datepicker-body */
  &-body {
    padding: 10px;
    min-height: 232px;
    min-width: 278px;
    border: 1px solid $border-color;
    @include border-radius(0 0 3px 3px);

    .days.weeks {
      position: relative;
      z-index: 1;
    }

    /* .bs-datepicker-body table */
    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;

      th {
        font-size: 13px;
        color: $cep-datepicker-weekday-color;
        font-weight: 400;
        text-align: center;
      }

      td {
        color: $cep-datepicker-body-btn-color;
        text-align: center;
        position: relative;
        padding: 0;

        span {
          display: block;
          margin: 0 auto;
          font-size: 13px;
          position: relative;
          /*z-index: 1;*/
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          @include border-radius(50%);
        }

        &:not(.disabled):not(.week) span:not(.disabled):not(.is-other-month) {
          cursor: pointer;
        }

        &.is-highlighted:not(.disabled):not(.selected) span,
        span.is-highlighted:not(.disabled):not(.selected) {
          background-color: $cep-datepicker-body-btn-highlighted-color;
          transition: 0s;
        }

        &.is-active-other-month:not(.disabled):not(.selected) span,
        span.is-active-other-month:not(.disabled):not(.selected) {
          background-color: $cep-datepicker-body-btn-highlighted-color;
          transition: 0s;
          cursor: pointer;
        }

        span.disabled,
        &.disabled span {
          color: $cep-datepicker-body-btn-disabled-color;
        }

        span.selected,
        &.selected span {
          color: $cep-datepicker-body-btn-selected-color;
        }

        span.is-other-month,
        &.is-other-month span {
          color: rgba(0, 0, 0, 0.25);
        }

        &.active {
          position: relative;

          &.select-start:before {
            left: 35%;
          }

          &.select-end:before {
            left: -85%;
          }
        }

        span.active.select-start:after,
        span.active.select-end:after,
        &.active.select-start span:after,
        &.active.select-end span:after {
          content: '';
          display: block;
          position: absolute;
          z-index: -1;
          width: 100%;
          height: 100%;
          transition: 0.3s;
          top: 0;
          @include border-radius(50%);
        }

        &:before,
        span:before {
          content: '';
          display: block;
          position: absolute;
          z-index: -1;
          top: 6px;
          bottom: 6px;
          left: -2px;
          right: -2px;
          box-sizing: content-box;
          background: transparent;
        }

        &.active.select-start + td.active:before {
          left: -20%;
        }

        &:last-child.active:before {
          width: 125%;
          left: -25%;
          @include border-radius(0 3px 3px 0);
        }

        span[class*='select-'],
        &[class*='select-'] span {
          color: $cep-datepicker-body-btn-selected-color;
          @include border-radius(50%);
        }
      }

      /* .bs-datepicker-body table.days */
      &.days {
        td,
        span {
          &.active:not(.select-start):before,
          &.in-range:not(.select-start):before {
            background: $cep-datepicker-body-btn-highlighted-color;
          }
        }

        span {
          width: 32px;
          height: 32px;
          line-height: 32px;

          &.select-start {
            z-index: 2;
          }
          &.is-highlighted.in-range:before,
          &.in-range.select-end:before {
            background: none;
            right: 0;
            left: 0;
          }
        }

        td {
          &.select-start + td.select-end:before,
          &.select-start + td.is-highlighted:before,
          &.active + td.is-highlighted:before,
          &.active + td.select-end:before,
          &.in-range + td.is-highlighted:before,
          &.in-range + td.select-end:before {
            background: $cep-datepicker-body-btn-highlighted-color;
            width: 100%;
          }
        }
      }

      /* .bs-datepicker-body table.weeks */
      &.weeks {
        tr {
          td {
            &:nth-child(2).active:before {
              left: 0;
              width: 100%;
              @include border-radius(3px 0 0 3px);
            }
          }
        }
      }

      &:not(.weeks) {
        tr {
          td {
            &:first-child:before {
              @include border-radius(3px 0 0 3px);
            }
          }
        }
      }

      &.years {
        td {
          span {
            width: 46px;
            height: 46px;
            line-height: 45px;
            margin: 0 auto;
          }
        }

        tr:not(:last-child) {
          td {
            span {
              margin-bottom: 8px;
            }
          }
        }
      }

      &.months {
        td {
          height: 52px;

          span {
            padding: 6px;
            @include border-radius(15px);
          }
        }
      }
    }
  }

  /* .bs-datepicker-btns */
  &-container {
    padding: 15px;
  }

  .bs-media-container {
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  /* .bs-datepicker-predefined-btns */
  &-predefined-btns {
    button {
      width: 100%;
      display: block;
      height: 30px;
      background-color: $cep-datepicker-predefined-btn-bg;
      @include border-radius(4px);
      color: $cep-datepicker-predefined-btn-color;
      border: 0;
      margin-bottom: 10px;
      padding: 0 18px;
      text-align: left;
      transition: 0.3s;

      &:hover {
        background-color: $cep-datepicker-predefined-btn-bg-hover;
      }
    }
  }

  /* .bs-datepicker-buttons */
  &-buttons {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    padding-top: 10px;
    border-top: 1px solid $border-color;
  }
}

bs-datepicker-container,
bs-daterangepicker-container {
  z-index: 1080;
}

/* screen size < 1024px */
@media (max-width: 768px) {
  .bs-datepicker {
    &-multiple {
      display: flex;

      & + & {
        margin-top: 10px;
        margin-left: 0;
      }
    }
  }
}

@include theming('default', $cep-datepicker-color);
