// This is used to hide the inputs the focus service is using to scroll to.
.hidden-focus-input {
  height: 0px;
  padding: 0;
  border: none;
  opacity: 0;
  position: absolute;
}

.drop-zone {
  z-index: 999;
  padding: 1rem 1rem;
  cursor: pointer;
  transition: all 0.15s ease;
  text-align: center;
  color: #8898aa;
  border: 1px dashed #dee2e6;
  border-radius: 0.375rem;
  background-color: #fff;
  order: -1;
  overflow: hidden;

  &.disabled {
    & .question-content-wrapper,
    & > .form-control,
    & > .form-control  label, // This line seems necessary otherwise the label won't get any styling...
    & > span {
      opacity: 0.75;
      cursor: not-allowed;
    }
  }

  & .form-control {
    & input {
      display: none;
    }
  }

  & .upload-file-box {
    & .upload-file-item-progress {
      margin-top: 0.25rem;
    }
  }
}

.drop-zone-drag {
  border: 1px dashed $input-focus-border-color;
  border-radius: 0.375rem;
}

.drop-zone-hover {
  border: 1px solid $input-focus-border-color;
  border-radius: 0.375rem;
}

.drop-zone-box {
  padding: 20px 10px;
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
}

.drop-zone-box-remove {
  cursor: pointer;
}

.cdk-drag-preview {
  color: #007bff;
  background-color: white;
  opacity: 0.75;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  margin: 0.375rem 0;
  background: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drop-zone-box:last-child {
  border: none;
}

.drop-zone-list.cdk-drop-list-dragging .drop-zone-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.upload-file-item {
  margin-top: 10px;
  margin-bottom: 10px;
}

.upload-file-box {
  margin: 10px 0px;
}

.upload-file-item-name {
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

cep2-file-upload-item-form-field > button {
  background-color: transparent;
  border: none;
}
