.question-caption-annotation {
  display: inline-block;
  @extend .text-muted !optional;
}

.hidden-accessibility {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
}

.annotation i {
  margin-right: 5px;
}
